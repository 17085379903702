import * as React from "react"

const dstyle = {
    width: "100%",
    backgroundColor: "palegoldenrod",
    margin: "5px 0px 5px 0px",
}

const pstyle = {
    display: "inline-block",
    padding: "5px 0px 2px 2px",
    borderLeft: "5px solid darkgoldenrod",
    margin: "2px",
}

function DisclaimerBase(prop) {
    return (
        <div style={dstyle}>
            <p style={pstyle}><strong>{prop.s}</strong>  {prop.p}</p>
        </div>
    )
}

export default DisclaimerBase