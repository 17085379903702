import DisclaimerBase from "./DisclaimerBase"

function CourseworkDisclaimer(prop) {
    return (
        DisclaimerBase(
            {
                s: "Coursework Disclaimer:",
                p:"This project is coursework that is reused year to year; Details are left somewhat vague so as to not provide answers.",
            }
        )
    )
}

export default CourseworkDisclaimer